export enum SUPPLIER_FILTERS {
  TYPE = 'Supplier Type',
  NAME = 'Supplier Name',
  TOLL_FREE_NUMBER = 'Toll Free Number',
  PHONE_NUMBER = 'Phone Number',
  FAX_NUMBER = 'Fax Number',
  WEB_SITE = 'Web Site',
  EMAIL_ADDRESS = 'Email Address',
  SERVICE_LEVEL = 'Service Level',
}
