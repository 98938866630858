import { IAPIGridRequest, regex, Utilities } from '@wings-shared/core';
import { auditFields } from '@wings/shared';
export const fields = {
  ...auditFields,
  name: {
    label: 'Hotel Name*',
    rules: 'required|string|between:1,200',
  },
  externalId: {
    label: 'Hotel Identifier*',
    rules: 'required|string',
  },
  addressLine1: {
    label: 'Address Line 1',
    rules: 'string|between:1,200',
  },
  addressLine2: {
    label: 'Address Line 2',
    rules: 'string|between:1,200',
  },
  city: {
    label: 'City',
  },
  zipCode: {
    label: 'Zip',
    rules: 'string',
  },
  state: {
    label: 'State',
  },
  country: {
    label: 'Country*',
    rules: 'required',
  },
  localPhoneNumber: {
    label: 'Local Phone Number',
    rules: 'string',
  },
  faxNumber: {
    label: 'Fax Number',
    rules: 'string',
  },
  reservationEmail: {
    label: 'Reservation Email',
    rules: 'string|email',
  },
  frontDeskEmail: {
    label: 'Front Desk Email',
    rules: 'string|email',
  },
  website: {
    label: 'Website',
    rules: `string|between:1,2048|regex:${regex.urlV2}`,
  },
  airports: {
    value: [],
  },
  distance: {
    label: 'Distance from Airport',
    rules: `numeric|between:0.0,999.9|regex:${regex.numberWithTwoDecimal}`,
  },
  longitude: {
    label: 'Longitude',
    rules: 'numeric|between:0.0,999999999999.999999',
  },
  latitude: {
    label: 'Latitude',
    rules: 'numeric|between:0.0,999999999999.999999',
  },
  accessLevel: {
    label: 'Access Level*',
    rules: 'required',
  },
  status: {
    label: 'Status*',
    rules: 'required',
  },
  sourceType: {
    label: 'Source Type',
  },
};

export const airportRequest = (searchValue): IAPIGridRequest => {
  return {
    searchCollection: JSON.stringify([
      Utilities.getFilter('DisplayCode', searchValue),
      Utilities.getFilter('Name', searchValue, 'or'),
    ]),
    specifiedFields: [ 'AirportId', 'DisplayCode', 'Name', 'Status' ],
    filterCollection: JSON.stringify([ Utilities.getFilter('Status.Name', 'Active') ]),
  };
};
