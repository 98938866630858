export enum EVENT_FILTERS {
  ALL = 'All',
  EVENT_NAME = 'Name',
  DESCRIPTION = 'Description',
  EVENT_CATEGORY = 'Category',
  EVENT_TYPE = 'Type',
}

export enum REVIEW_COMPARISON_FILTERS {
  NAME = 'Name',
  WORLD_EVENT_TYPE = 'World Event Type',  
  WORLD_EVENT_CATEGORY_TYPE = 'World Event Category Type',
  APPROVAL_STATUS = 'Status',
}
